import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import Icon from "@mui/material/Icon";

import styles from "styles/jss/nextjs-material-kit-pro/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const { title, description, vertical, className } = props;
  const classes = useStyles();
 
  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [className]: className !== undefined,
  });

  return (
    <div className={infoAreaClasses}>
      
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {

  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
